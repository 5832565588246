import { NEVER_RELOAD, useSingleCallResult } from 'state/multicall/hooks'
import { useDegenNameResolver } from './useContract'
import { useActiveWeb3React } from './web3'

export default function useDegenName(address?: string | null): string | null {
  const { account } = useActiveWeb3React()
  const degenNameResolver = useDegenNameResolver()
  const call = useSingleCallResult(degenNameResolver, 'defaultNames', [address ?? account ?? undefined], NEVER_RELOAD)
  const result = call?.result?.[0]
  return result ? `${result}.degen` : null
}
