import JSBI from 'jsbi'

export const INIT_CODE_HASH = '0xf134c874b39e61378a3f19b6f15a0e83c6916c54524901806f3e1ca3da7b2243'

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)
