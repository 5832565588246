import React from 'react'
import styled, { css } from 'styled-components/macro'

export const Glow = css`
  // animation: glow 10s ease-in-out infinite;
  // -webkit-animation: glow 10s ease-in-out infinite;

  //box-shadow: 0 0 20px rgba(249, 243, 221, 0.1), 0 0 30px rgba(249, 243, 221, 0.1), 0 0 40px rgba(249, 243, 221, 0.1),
  //  0 0 50px rgba(249, 243, 221, 0.1), 0 0 60px rgba(249, 243, 221, 0.1), 0 0 70px rgba(249, 243, 221, 0.1),
  //  0 0 80px rgba(249, 243, 221, 0.1);

  /* box-shadow: 0 0 10px rgba(249, 243, 221, 0.1), 0 0 15px rgba(249, 243, 221, 0.1), 0 0 20px rgba(249, 243, 221, 0.1),
    0 0 25px rgba(249, 243, 221, 0.1), 0 0 30px rgba(249, 243, 221, 0.1), 0 0 45px rgba(249, 243, 221, 0.1),
    0 0 40px rgba(249, 243, 221, 0.1); */

  @keyframes glow {
    0% {
    }
    50% {
      //  box-shadow: 0 0 20px rgba(249, 243, 221, 0.1), 0 0 30px rgba(249, 243, 221, 0.1), 0 0 40px rgba(249, 243, 221, 0.1),
      //  0 0 50px rgba(249, 243, 221, 0.1), 0 0 60px rgba(249, 243, 221, 0.1), 0 0 70px rgba(249, 243, 221, 0.1),
      //  0 0 80px rgba(249, 243, 221, 0.1);
      //box-shadow: 0 0 20px rgba(249, 243, 221, 0.15), 0 0 30px rgba(249, 243, 221, 0.15),
      //  0 0 40px rgba(249, 243, 221, 0.15), 0 0 50px rgba(249, 243, 221, 0.2), 0 0 60px rgba(249, 243, 221, 0.2),
      //  0 0 70px rgba(139, 92, 246, 0.25), 0 0 80px rgba(139, 92, 246, 0.25);
    }
    100% {
      //box-shadow: 0 0 20px rgba(249, 243, 221, 0.1), 0 0 30px rgba(249, 243, 221, 0.1), 0 0 40px rgba(249, 243, 221, 0.1),
      //  0 0 50px rgba(249, 243, 221, 0.1), 0 0 60px rgba(249, 243, 221, 0.1), 0 0 70px rgba(249, 243, 221, 0.1),
      //  0 0 80px rgba(249, 243, 221, 0.1);
      //box-shadow: 0 0 10px rgba(249, 243, 221, 0.1), 0 0 15px rgba(249, 243, 221, 0.1), 0 0 20px rgba(249, 243, 221, 0.1),
      //  0 0 25px rgba(249, 243, 221, 0.1), 0 0 30px rgba(249, 243, 221, 0.1), 0 0 45px rgba(249, 243, 221, 0.1),
      //  0 0 40px rgba(249, 243, 221, 0.1);

      //box-shadow: 0 0 20px rgba(249, 243, 221, 0.2), 0 0 30px rgba(249, 243, 221, 0.2), 0 0 40px rgba(249, 243, 221, 0.2),
      //  0 0 50px rgba(105, 184, 227, 0.6), 0 0 60px rgba(105, 184, 227, 0.6), 0 0 70px rgba(105, 184, 227, 0.6),
      //  0 0 80px rgba(105, 184, 227, 0.6);
    }
  }

  @-webkit-keyframes glow {
    0% {
      //box-shadow: 0 0 20px rgba(249, 243, 221, 0.1), 0 0 30px rgba(249, 243, 221, 0.1), 0 0 40px rgba(249, 243, 221, 0.1),
      //0 0 50px rgba(249, 243, 221, 0.1), 0 0 60px rgba(249, 243, 221, 0.1), 0 0 70px rgba(249, 243, 221, 0.1),
      //0 0 80px rgba(249, 243, 221, 0.1);
    }
    //50% {
    //  box-shadow: 0 0 20px 3px rgba(230, 0, 122, 0.4) inset;
    //}
    100% {
      //box-shadow: 0 0 20px rgba(249, 243, 221, 0.2), 0 0 30px rgba(249, 243, 221, 0.2), 0 0 40px rgba(249, 243, 221, 0.2),
      //0 0 50px rgba(105, 184, 227, 0.6), 0 0 60px rgba(105, 184, 227, 0.6), 0 0 70px rgba(105, 184, 227, 0.6),
      //0 0 80px rgba(105, 184, 227, 0.6);
    }
  }
`

export const BodyWrapper = styled.div<{ margin?: string }>`
  position: relative;
  margin-top: ${({ margin }) => margin ?? '0px'};
  max-width: 480px;
  width: 100%;

  font-family: 'Ubuntu Mono', monospace !important;

  //  background: linear-gradient(180deg, #5e56b3, #36648b, #488ba3, #4f7cb3, #63a7bf);

  background: rgb(30, 41, 59);
  // backdrop-filter: sepia(90%);
  border-radius: 8px;
  margin-top: 1rem;
  //  border: 1px solid #f9f3dd;

  ${Glow}

  .stage {
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, ...rest }: { children: React.ReactNode }) {
  return <BodyWrapper {...rest}>{children}</BodyWrapper>
}
