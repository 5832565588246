import React, { useMemo } from 'react'
import { Text, TextProps } from 'rebass'
import styled, {
  createGlobalStyle,
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components'
import { useIsDarkMode } from '../state/user/hooks'
import { Colors } from './styled'

export * from './components'

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

export function colors(darkMode: boolean): Colors {
  return {
    // base
    white,
    black,

    // text
    text1: darkMode ? '#f9f3dd' : '#000000',
    text2: darkMode ? '#f9f3dd' : '#f9f3dd',
    text3: darkMode ? '#C3C5CB' : '#888D9B',
    text4: darkMode ? '#C3C5CB' : '#f9f3dd',
    text5: darkMode ? '#C3C5CB' : '#EDEEF2',

    // backgrounds / greys

    bg01: 'black',
    bg0: darkMode ? 'rgba(139, 92, 246, 0.25)' : 'rgba(139, 92, 246, 0.25)',
    bg1: darkMode ? 'rgba(139, 92, 246, 0.25)' : 'rgba(139, 92, 246, 0.25)',
    bg2: darkMode ? 'rgba(139, 92, 246, 0.25)' : 'rgba(139, 92, 246, 0.25)',
    bg3: darkMode ? 'rgba(139, 92, 246, 0.25)' : 'rgba(139, 92, 246, 0.25)',
    bg4: darkMode ? 'rgba(139, 92, 246, 0.25)' : 'rgba(139, 92, 246, 0.25)',
    bg5: darkMode ? 'rgba(139, 92, 246, 0.25)' : 'rgba(139, 92, 246, 0.25)',
    bg6: darkMode ? 'rgba(139, 92, 246, 0.25)' : 'rgba(139, 92, 246, 0.25)',

    //specialty colo

    modalBG: darkMode ? 'rgba(139, 92, 246, 0.25)' : 'rgba(139, 92, 246, 0.25)',
    advancedBG: darkMode ? 'rgba(139, 92, 246, 0.25)' : 'rgba(139, 92, 246, 0.25)',

    //primary colors
    primary1: darkMode ? '#fff' : '#fff',
    primary2: darkMode ? '#fff' : '#fff',
    primary3: darkMode ? '#fff' : '#fff',
    primary4: darkMode ? '#fff' : '#fff',
    primary5: darkMode ? 'rgba(139, 92, 246, 0.25)' : 'rgba(139, 92, 246, 0.25)',

    primary1_30: 'rgba(139, 92, 246, 0.25)',
    primaryTransparent: 'rgba(139, 92, 246, 0.25)',

    // color text
    primaryText1: darkMode ? '#fff' : '#fff',

    // secondary colors
    secondary1: darkMode ? '#fff' : '#fff',

    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#FDEAF1',

    secondary1_30: 'rgba(0,0,0, 0.1)',
    secondary1_10: 'rgba(0,0,0, 0.1)',

    dark0: 'rgba(139, 92, 246, 0.25)',
    dark1: 'rgba(139, 92, 246, 0.25)',
    //dark2: 'rgba(10,14,36, 0.9)',
    dark2: 'rgba(0,0,0,0.5)',
    dark3: 'rgba(139, 92, 246, 0.25)',
    dark4: 'rgba(139, 92, 246, 0.25)',
    dark5: 'transparent',
    darkTransparent: 'rgba(0,0,0, 0.1)',
    darkTransparent2: 'rgba(0,0,0, 0.1)',
    darkTransparent3: 'rgba(0,0,0, 0.1)',

    bgGradient: `linear-gradient(90deg, rgba(0,0,0, 0.1) 0%, rgba(0,0,0, 0.1) 35%, rgba(0,0,0, 0.1) 100%)`,

    // other
    // red1: 'rgba(139, 92, 246, 0.25)',
    // red2: 'rgba(139, 92, 246, 0.25)',
    // red3: 'rgba(139, 92, 246, 0.25)',
    // green1: 'rgba(139, 92, 246, 0.25)',

    // yellow1: 'rgba(139, 92, 246, 0.25)',
    // yellow2: 'rgba(139, 92, 246, 0.25)',
    // yellow3: 'rgba(139, 92, 246, 0.25)',
    // blue1: 'rgba(139, 92, 246, 0.25)',
    // blue2: 'rgba(139, 92, 246, 0.25)',

    // error: 'rgba(139, 92, 246, 0.25)',
    // success: 'rgba(139, 92, 246, 0.25)',
    // warning: 'rgba(139, 92, 246, 0.25)',

    red1: '#fff',

    red2: '#F82D3A',
    red3: '#D60000',
    green1: '#fff',

    yellow1: '#fff',
    yellow2: '#fff',
    yellow3: '#fff',
    blue1: '#fff',
    blue2: '#fff',

    error: '#FD4040',
    success: '#fff',
    warning: '#fff',

    // dont wanna forget these blue yet
    // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: darkMode ? '#000' : '#f9f3dd',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  label(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'text1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'blue1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow3'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  },
}

export const ThemedBackground = styled.div<{ backgroundColor?: string | undefined }>`
  position: fixed;
  /* top: 0; */
  top: 30vh;
  /* left: calc(-100vw / 2); */
  right: 0;
  pointer-events: none;
  /* max-width: 100vw !important; */
  width: 100vw;
  /* width: 200vw; */
  height: 200vh;

  mix-blend-mode: color;
  /* background: ${({ backgroundColor }) =>
    `radial-gradient(50% 50% at 50% 50%, ${
      backgroundColor ? backgroundColor : '#fc077d10'
    } 0%, rgba(255, 255, 255, 0) 100%)`}; */
  background: transparent radial-gradient(closest-side at 50% 50%, #000 0%, #20212400 100%) 0% 0% no-repeat padding-box;
  opacity: 0.6;
  transform: translateY(-100vh);
  will-change: background;
  transition: background 450ms ease;
`

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: "Ubuntu Mono", monospace !important;


  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: "Ubuntu Mono", monospace !important;


  }
}

html,
body {
  margin: 0;
  padding: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */


 font-family: "Ubuntu Mono", monospace !important;
}
body::-webkit-scrollbar {
  display: none;
}


 a {
   color: ${colors(false).blue1}; 
 }

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss01' on, 'ss02' on,  'cv01' on, 'cv03' on;
  
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};

 // background: linear-gradient(180deg,#5e56b3,#36648B,#488ba3,#4f7cb3,#63a7bf);
 background:#0f172a;
  
  background-repeat: no-repeat;
}

body {
  min-height: 100vh;
  background-position: 0 -30vh;
  background-repeat: no-repeat;

}
`
