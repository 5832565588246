/**
 *
 * https://github.com/diffusion-fi/v2-periphery/blob/main/scripts/config/config.ts
 *
 * When changing this also update: cypress/integration/contracts.ts
 *
 */

const PERIPHERY_TESTNET = {
  factory: '0x81BC50a2df9cE424843e3c17110E1ab1FedCD4b8',
  weth9: '0xcc491f589B45d4a3C679016195B3FB87D7848210',
  router: '0x72bd489d3cF0e9cC36af6e306Ff53E56d0f9EFb4',
  mockUSDC: '0xc48Efe267a31b5Af4cFDb50C8457914aadB0b875',
  mockEVMOS: '0xf1361Dc7DFB724bd29FE7ade0CdF9785F2Bc20E6',
  mockATOM: '0x9832169B33DC5777D3d28572f35E0a537Ff7A04C',
  mockOSMOSIS: '0x1dccd8025688e39C72f2539C6f00d77bd6678425',
  multicall2: '0x1B7c09Ac8aA1e6e1d299d9301B539A368eD4c176',
  LHS: '0x7c21d6A51d6f591A95470f1F262C9c804c4CEAc3',
  RHS: '0xD3607915d934576EcdC389E7DBc641097fd5A0dE',
  testerAddress: '0x1662BfeA0Af3515baf9DAb3f0961Dc26DD35202B',
  //0x851e5cE9fa409B731f980a5E00FA889b58D9037D
  // 0xA2c659531B15bFf2556Ea7E12D477D3C8761ACD9
  //0x95BF964f113a75a3974E8164105e6e5A8D743b87
  // 0x62154D72C202f04CA50a3Ba5630052D0348f337A
  rewardToken: '0x7e806D59528F6Fa7CCcAdb4821Dd42551113DEFc',
  secondaryRewardToken: '0x9AC19677BD6B1a3ba046C33f4D2f1952cA0e9a13',
  miniChef: '0x0fCee557E3eB94913e202eF91314f14298591a61',
  complexRewarderTime: '0x2916d2e0B675e6993250f2DB9764Cd7fD5379C04',
  diffusion: '',
}

const MAINNET_PERIPHERY = {
  factory: '0xA5E57CaB76caa09F66280F9Eb1529ed1059E87ba',
  weth9: '0xEb54dACB4C2ccb64F8074eceEa33b5eBb38E5387',
  router: '0x5A8e4e0dD630395B5AFB8D3ac5b3eF269f0c8356',
  multicall2: '0x29f034f9B40dAf0ee2f2e5577785805aeD365f40',
  //

  minichef: '0x892EA061C68fB83F2992b974366E463975120d49',
  diffusion: '0x8326321a7777Cd5D650c1da6A497f0D06633781e',
}

export const MAINNET = {
  ...MAINNET_PERIPHERY,
  diffusionbar: '0x169546A07c22244692EF1B48D8d55C03d7cEd61d',
  airdrop: '0xb9A52744213eA63D57F389622e1d569Bb4705207',
}

export const TESTNET = {
  ...PERIPHERY_TESTNET,
  airdrop: '0x2F7Ad6172388aED2017FBfA1631724F172360Ab1',
  diffusionbar: '0x2314D451a1A2519501119f105dd1D65D0CE4E93b',
}
